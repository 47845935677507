import './App.css';
import React, { useState, useEffect } from 'react';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyNavbar from './components/Navbar';
import Homepage from './components/Homepage';
import About from './components/About';
import Technologies from './components/Technologies';
import Projects from './components/Projects';
import Contact from './components/Contact';
import TestimonialPage from './components/testimonial';
import WorkingField from './components/WorkingField';
import Footer from './components/footer';
function App() {
  const [mode,setMode] = useState('light')
  const changeMode = (mode) => {
    if(mode==='light')
    {
      // console.log("dark mode : ",mode);
      document.body.style.backgroundColor = "#F5F5F5";
      setMode('light');
    }
    else
    {
      // console.log("dark mode : ",mode);
      setMode('dark');
      document.body.style.backgroundColor = "black";
    }
  }
  useEffect(()=>{
    changeMode(mode);
  },[])

  const clearCacheData = () => {
    try{
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    catch(err){
      console.log("caches variable not found");
    }
    console.log('Complete Cache Cleared')
    return;
  };
  clearCacheData()

  return (
    <div>
      <MyNavbar theme={mode} changeModeFunction={changeMode}/>
          <Homepage theme={mode}/>
          <About theme={mode}/>
          {/* <Technologies theme={mode}/> */}
          {/* <Projects theme={mode}/>  */}
          <WorkingField theme={mode}/>
          <TestimonialPage  theme={mode}/>
          <Contact theme={mode}/>
          <Footer theme={mode}/>
      </div>
  );
}

export default App;
