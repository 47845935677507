import React from 'react'
import "../css/About.css"
import {Row, Col} from 'react-bootstrap'

export default function About(props) {
    return (
        <div className='aboutMain' id="about">
            <Row style={{margin:0,padding:0}}>
                <Col lg={6} md={6} sm={12} style={{margin:'0',padding:'0'}}>
                    <div className={(props.theme)==='dark'?'aboutDark':'aboutLight'}>
                        <h1>"Justice Served, Rights Protected.</h1>
                        <p className={(props.theme)==='dark'?'paragraph content-paragraphDark':'paragraph content-paragraphLight'}>Welcome to my legal practice.</p>
                        <p className={(props.theme)==='dark'?'paragraph content-paragraphDark':'paragraph content-paragraphLight'}>I am a dedicated and passionate lawyer, fresh into the profession with a strong commitment to delivering exceptional legal services.</p>
                        <p className={(props.theme)==='dark'?'paragraph content-paragraphDark':'paragraph content-paragraphLight'}>My approach is rooted in a deep understanding of the law, combined with a fresh perspective and innovative thinking.</p>
                        <p className={(props.theme)==='dark'?'paragraph content-paragraphDark':'paragraph content-paragraphLight'}>I believe in providing personalized attention to each client, ensuring that your legal matters are handled with the utmost care and professionalism.</p>
                        <p className={(props.theme)==='dark'?'paragraph content-paragraphDark':'paragraph content-paragraphLight'}>Whether you need guidance on civil matters, criminal defense, corporate law, or any other legal issue, I am here to assist you with integrity and determination. My goal is to protect your rights, provide clear and honest legal advice, and represent your interests to the fullest extent.</p>
                        <p className={(props.theme)==='dark'?'paragraph content-paragraphDark':'paragraph content-paragraphLight'}>Let's work together to navigate the complexities of the legal system and achieve the best possible outcome for your case.</p>
                        <p className={(props.theme)==='dark'?'paragraph content-paragraphDark':'paragraph content-paragraphLight'}>
                        &nbsp;
                            <a href="#contact" className="link">Get Consultaion</a>.
                        </p>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} style={{margin:'0',padding:'0'}}>
                    <div className='aboutImageDiv'>
                        <img className='aboutImageDivImgTag' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTADr-3PrecoVO1hoAmq1qUaXmfmPNM30r2Nw&usqp=CAU'></img>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
