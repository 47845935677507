import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/WorkingField.css'

const WorkingField = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const fields = [
    {
      title: 'Criminal Law',
      description: 'Expertise in handling criminal cases including defense and prosecution.',
    //   imageUrl: 'url-to-criminal-law-image',
    },
    {
      title: 'Family Law',
      description: 'Specialized in divorce, child custody, and other family-related matters.',
    //   imageUrl: 'url-to-family-law-image',
    },
    {
      title: 'Corporate Law',
      description: 'Advising businesses on legal matters, compliance, and transactions.',
    //   imageUrl: 'url-to-corporate-law-image',
    },
    // Add more fields as needed
  ];

  return (
    <div className="working-field" id='workingfield'>
      <h2>Areas of Practice</h2>
      <Slider {...settings}>
        {fields.map((field, index) => (
          <div key={index} className="slide">
            <div
              className="slide-image"
              style={{ backgroundImage: `url(${field.imageUrl})` }}
            />
            <h3>{field.title}</h3>
            <p>{field.description}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default WorkingField;
