import React from 'react'
import "../css/GetInTouch.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notify = () => toast.success("Thanks! Your submission is recieved successfully, Will reach you soon",{position:'top-center'});

const addDataIntoAirtable = async(obj) =>{
    let data = {
        records:[
            {
                fields: obj
            }
        ]
    }
    const URL = 'https://api.airtable.com/v0/apph62HG48Di79ddG/My%20Portfolio%20Form%20Submission';
    let submitted = await fetch(URL,{
        body:JSON.stringify(data),
        method: 'POST',
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer keygg0TUOgndzmedq`
        }
    }).then(response=>response.json())
    .then(data=>{
        notify();
    })
    console.log("Submitted data : ",submitted);
}

const display = (event) =>{
    event.preventDefault();
    console.log("Inside Display: ",event);
    const obj = {
        "Name": event.target.name.value,
        "Email": event.target.email.value,
        "Phone": event.target.phone.value,
        "City": event.target.city.value,
        "Message": (event.target.message.value.length>0)?event.target.message.value:null,
        "Not Checked Yet": true
    }
    addDataIntoAirtable(obj);
}

export default function Contact(props) {
    return (
        <div className='contactMain' id='contact'>
            <div className={(props.theme)==='dark'?'contactDark':'contactLight'}>
                <div>
                    <h1 className='h1'>Get Consultation</h1>
                    <p className={(props.theme)==='dark'?'contactParagraph content-contactParagraphDark':'contactParagraph content-contactParagraphLight'}>
                        Get in touch via the form below
                    </p>
                </div>
                <div className='formDiv'>
                    <form onSubmit={display} style={{width:'100%',marginLeft:'15%',marginRight:'15%'}}>
                        <div className="mb-1">
                            <input type="text" className="form-control" id="form-name" name='name' aria-describedby="name" placeholder='Name' required/>
                        </div>
                        {/* <div className="mb-1 my-2">
                            <input type="email" className="form-control" id="email" name='email' placeholder='Email' required/>
                        </div> */}
                        <div className="mb-1 my-2">
                            <input type="text" className="form-control" id="phone" name='phone' aria-describedby="phone" placeholder='Phone' required/>
                        </div>
                        <div className="mb-1 my-2">
                            <input type="text" className="form-control" id="city" name='city' aria-describedby="city" placeholder='City' required/>
                        </div>      
                        <div className="mb-1 my-2">
                            <textarea className="form-control" aria-label="With textarea" name='message' id='message' placeholder='Message'></textarea>
                        </div>
                        <button type="submit" className="btn btn-primary my-3">Submit</button>
                        <ToastContainer/>
                    </form>
                </div>
                <div>
                    <p className={(props.theme)==='dark'?'contactParagraph content-contactParagraphDark':'contactParagraph content-contactParagraphLight'}>
                        or by emailing <a href='mailto:adv.sameerbaig@gmail.com' className='link'>adv.sameerbaig@gmail.com</a>
                        .
                    </p>
                </div>
            </div>
        </div>
    )
}
