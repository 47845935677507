import React from 'react';
import '../css/footer.css'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                {/* <div className="footer-section about">
                    <h2>About Us</h2>
                    <p>.</p>
                </div> */}
                {/* <div className="footer-section links">
                    <h2>Quick Links</h2>
                    <ul>
                        <li><a href="/about">About</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/contact">Contact</a></li>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div> */}
                {/* <div className="footer-section contact">
                    <h2>Contact Us</h2>
                    <p>Email: info@yourlawfirm.com</p> 
                    <p>Phone: (123) 456-7890</p>
                    <p>Address: 123 Legal St., City, State</p>
                </div> */}
            </div>
            <div className="footer-bottom">
                &copy; {new Date().getFullYear()} Advocate Sameer Baig | All Rights Reserved
            </div>
        </footer>
    );
}

export default Footer;
