import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../css/testimonial.css"

const testimonials = [
  {
    name: "Sameer khan",
    title: "student",
    message: "This lawyer provided excellent legal services, helping us win a critical case. Highly recommended!"
  },
  {
    name: "Sohail ali malik",
    title: "Founder, Samsinfosoft",
    message: "Professional, diligent, and always available for advice. A great legal partner."
  },
  {
    name: "Owais khan",
    title: "IT proffessional",
    message: "Outstanding service with attention to detail. Helped us navigate complex legal challenges."
  },
  {
    name: "Javed khan",
    title: " CEO Project d",
    message: "best legal advisor ."
  }
];

const Testimonial = ({ name, title, message }) => (
  <div className="testimonial">
    <p className="message">"{message}"</p>
    <p className="name">- {name}</p>
    <p className="title">{title}</p>
  </div>
);

const TestimonialPage = () => (
<div className="testimonial-page" id='testimonial'>
  <h2>What Our Clients Say</h2>
  <Slider >
    {testimonials.map((testimonial, index) => (
      <div key={index} className="slide">
        <div
          className="slide-image"
          style={{ backgroundImage: `url(${testimonial.name})` }}
        />
         <h3>{testimonial.name}</h3>
        <h3>{testimonial.title}</h3>
        <p>{testimonial.message}</p>
       
      </div>
       ))}
       </Slider>
     </div>
);

export default TestimonialPage;

